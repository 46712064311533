.toggle-box {
  $this: &;
  padding: 0 0 32px;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #2f1f19;
  position: relative;

  &__content {
    height: auto;
    max-height: 110px;
    overflow: hidden;
    transition: max-height 0.6s linear;

    #{$this}--residence & {
      max-height: 100px;
    }
  }

  &__input {
    display: none;

    + #{$this}__label {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      font-weight: 400;
      font-size: 15px;
      line-height: 25px;
      text-decoration-line: underline;
      color: #4264fd;
      background: #fff;
      cursor: pointer;
    }

    &:checked {
      ~ #{$this}__label {
        opacity: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        #{$this}--unidirectional & {
          display: none;
        }
      }

      ~ #{$this}__content {
        max-height: 3000px;
        margin-bottom: -30px;
      }
    }
  }
}
